// Base
$base-width: .8rem;

// Fonts
$font-default: 'Jost';
$font-headline: 'Antic Slab';

// Colors
$mainfontcolor: #232227;
$secondfontcolor: #fff;
$body-bg: white;
$body-color: black;
$header-bg: white;
$footer-bg: blue;
$link-color: $mainfontcolor;
$link-color-hover: green;
$button-bg: black;
$button-bg-hover: purple;
$button-color: black;
$button-color-hover: black;
$headline1-color: black;
$headline2-color: red;
$filter-color-1: grayscale(100%);
$filter-color-3: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);

$divider: #ccc;

$mainyellow: #FDF21C;
$primary: $mainyellow;
$opacity_yellow: rgba(253, 242, 28, 0.8);
$opacity_blue: rgba(0, 68, 143, 0.8);
$mainblue: #00448F;
$maingrey: #DCDCDC;
$darkgrey: #232227;
$border-grey: #3b3b3b;

// Widths
$max: 2560px;
$boxed: 1520px;
$small: 1100px;

// Transition
$transition: all .3s ease-in-out;

// Breakpoints
$breakpoints: ("xs": 480px, "sm": 576px, "md": 768px, "lg": 992px, "xl": 1200px, "xxl": 1400px, "xxxl": 1680px, "xxxxl": 1921px,);
