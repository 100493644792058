.block-introduction {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0;
    @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
        flex-direction: row;
        height: 56rem; }
    @media only screen and (min-width: #{map-get($breakpoints, xxxl)}) {
        height: 96.2rem; }

    @media only screen and (min-width: #{map-get($breakpoints, xxxxl)}) {
        height: auto; }

    .intro--side {
        height: 100%;
        flex-basis: 50%;
        position: relative;
        overflow: hidden;

        .intro--content_wrap {
            height: 100%;

            img {
                object-position: center;
                object-fit: cover;
                height: unset;
                filter: grayscale(1);
                @media only screen and (min-width: #{map-get($breakpoints, md)}) {
                    height: 100%; } }

            .intro--choice_teaser {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                font-size: 4.5rem;
                font-family: $font-headline;

                @media only screen and (min-width: #{map-get($breakpoints, lg)}) {
                    font-size: 6.5rem; }

                @media only screen and (min-width: #{map-get($breakpoints, xl)}) and (max-width: #{map-get($breakpoints, xxxl)}) {
                    height: 100%;
                    width: 100%; }

                @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
                    font-size: 8.5rem; }

                .intro-wrapanchor {
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    top: 0; }

                a.intro-middleanchor {
                    margin-top: 6rem;

                    @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
                        position: absolute;
                        top: 60%;
                        opacity: 0;
                        margin-top: 2rem; }

                    span {
                        font-family: "Jost";
                        font-weight: 400;
                        font-size: 2.5rem;
                        gap: 2rem;
                        display: flex;
                        align-items: center;
                        transition: all .3s;

                        @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
                            padding-bottom: 6rem;
                            font-size: 3.5rem; }

                        svg {
                            width: 3.5rem;
                            height: auto;
                            fill: $secondfontcolor; } } } }

            .intro--choice_teaser.left {
                background-color: $opacity_yellow;
                color: $mainfontcolor;
                padding: 4.25rem 6.4rem;
                animation-name: introHoverReverse;
                animation-duration: .3s;

                a.intro-middleanchor {
                    animation-name: introHoverAnkerReverse;
                    animation-duration: .3s;

                    span {
                        animation-name: introArrowReverse;
                        animation-duration: .3s; } }

                @media only screen and (min-width: #{map-get($breakpoints, lg)}) {
                    padding: 8.25rem 8.4rem; }

                @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
                    padding: 12.25rem 3.5rem; }

                @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
                    padding: 16.25rem 13.4rem;

                    &:hover {
                        animation-name: introHover;
                        animation-iteration-count: 1;
                        animation-duration: .3s;
                        animation-fill-mode: forwards;
                        a.intro-middleanchor {
                            animation-name: introHoverAnker;
                            animation-iteration-count: 1;
                            animation-duration: .3s;
                            animation-fill-mode: forwards;
                            text-decoration: none;
                            span {
                                animation-name: introArrow;
                                animation-iteration-count: 1;
                                animation-duration: .3s;
                                animation-fill-mode: forwards;
                                text-decoration: none; } } } }
                a.intro-middleanchor {
                    &:hover {
                        color: $mainfontcolor; }
                    span {
                        svg {
                            fill: $mainfontcolor; } } } }

            .intro--choice_teaser.right {
                background-color: $opacity_blue;
                color: $secondfontcolor;
                padding: 4.25rem 9.78rem;
                animation-name: introHoverReverse;
                animation-duration: .3s;

                a.intro-middleanchor {
                    animation-name: introHoverAnkerReverse;
                    animation-duration: .3s;

                    span {
                        animation-name: introArrowReverse;
                        animation-duration: .3s; } }

                @media only screen and (min-width: #{map-get($breakpoints, lg)}) {
                    padding: 8.25rem 10.6rem; }

                @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
                    padding: 12.25rem 9.4rem; }

                @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
                    padding: 16.25rem 19.5rem;

                    &:hover {
                        animation-name: introHover;
                        animation-iteration-count: 1;
                        animation-duration: .3s;
                        animation-fill-mode: forwards;
                        a.intro-middleanchor {
                            animation-name: introHoverAnker;
                            animation-iteration-count: 1;
                            animation-duration: .3s;
                            animation-fill-mode: forwards;
                            text-decoration: none;
                            span {
                                animation-name: introArrow;
                                animation-iteration-count: 1;
                                animation-duration: .3s;
                                animation-fill-mode: forwards;
                                text-decoration: none; } } } }

                a.intro-middleanchor {
                    color: $secondfontcolor;
                    &:hover {
                        color: $secondfontcolor; } } } } } }

@keyframes introHover {
    0% {
        padding-bottom: 18.25rem; }
    100% {
        padding-bottom: 45rem; } }

@keyframes introHoverAnker {
    0% {
        opacity: 0; }
    100% {
        opacity: 1; } }

@keyframes introArrow {
    0% {
        gap: 2rem; }
    100% {
        gap: 3rem; } }


@keyframes introHoverReverse {
    0% {
        padding-bottom: 45rem; }
    100% {
        padding-bottom: 16rem; } }

@keyframes introHoverAnkerReverse {
    0% {
        opacity: 1; }
    100% {
        opacity: 0; } }

@keyframes introArrowReverse {
    0% {
        gap: 3rem; }
    100% {
        gap: 2rem; } }
