.block-openingblock {
    margin-bottom: 8rem;
    display: flex;
    justify-content: center;

    @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
        margin-bottom: 14rem; }

    .openingblock_wrapper {
        -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
        padding: 2.5rem;
        min-width: 100%;

        @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
            padding: 3.5rem; }

        @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
            min-width: auto; }

        @media only screen and (min-width: #{map-get($breakpoints, md)}) {
            padding: 8rem; }

        @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
            width: 80rem; }

        @media only screen and (min-width: #{map-get($breakpoints, lg)}) and (max-width: #{map-get($breakpoints, xxxl)}) {
            flex-basis: 50%; }


        h3 {
            font-size: 2.4rem;
            font-weight: 400;
            line-height: 40px;
            display: flex;
            margin-bottom: 3.75*$base-width;
            align-items: center;
            text-transform: uppercase;

            @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
                font-size: 3.5rem; }

            @media only screen and (min-width: #{map-get($breakpoints, lg)}) {
                font-size: 4rem; }

            span {
                padding-right: 15px;
                svg {
                    width: 3.4rem;
                    height: auto;
                    fill: $mainfontcolor; } } }

        .table-wrapper {
            table {
                tr {
                    justify-content: space-between;
                    line-height: 33px;
                    td {
                        font-size: 1.8rem;
                        @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
                            font-size: 2rem; } } }
                tr {
                    td:nth-of-type(1) {
                        font-weight: 500;
                        padding-right: 1rem; } }

                tr:nth-of-type(1) {
                    padding-bottom: 1rem; }

                tr:nth-of-type(2) {
                    padding-bottom: 2rem; }

                tr:nth-of-type(3) {
                    padding-bottom: 1.5rem; } }


            p {
                font-style: italic; }

            div.dscr {
                display: flex;
                flex-direction: column;
                line-height: 28px;
                font-size: 1.8rem;
                padding-top: 1rem;
                @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
                    font-size: 2rem;
                    padding-top: 2rem;
                    line-height: 33px; }

                strong {
                    font-weight: 500; } } } } }
