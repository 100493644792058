.block-contact-form {
    padding: 35px 20px;
    padding-top: 15rem;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (min-width: #{map-get($breakpoints, xxl)}) {
        flex-direction: row; }

    // @media (min-width: #{map-get($breakpoints, xl)})
    //     padding: 15rem 13rem

    .container, .grid {
        @media (max-width: #{map-get($breakpoints, sm)}) {
            padding: 0; }
        .col {
            @media (max-width: #{map-get($breakpoints, sm)}) {
                padding: 0; } } }

    .form-headline {
        margin-bottom: 2rem;

        @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
            margin-bottom: 4rem; } }


    input,
    select,
    textarea {
        padding: 1.5rem;
        height: auto;
        font-size: 1.6rem; }

    textarea {
        height: 150px;
        padding: 15px; }

    .form-row {
        margin: 0;
        padding: 0; }

    .form-group {
        display: flex;
        flex-direction: column;
        padding: 10px 20px;
        @media (max-width: #{map-get($breakpoints, sm)}) {
            padding: 0; } }

    .contact-form-form-container,
    .contact-form-map-container,
    .contact-form-contact-container {
        padding: 0 40px;

        @media (max-width: #{map-get($breakpoints, xxl)}) {
            padding: 20px 0; }
        @media (max-width: #{map-get($breakpoints, sm)}) {
            padding: 0; } }

    .contact-form-contact-container {
        .contact-form-adress-container,
        .contact-form-info-container,
        .contact-form-social-container {
            margin-bottom: 25px; }

        h3 {
            margin-bottom: 25px; } }

    .contact-form-form-container {
        h4 {
            margin-bottom: 25px; } }

    .contact-form-icon-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .contact-form-info-icon {
            display: flex;
            align-items: center;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            img {
                width: 20px;
                height: 20px; } }
        a {
            color: $mainfontcolor;
            text-decoration: none;
            &:hover {
                color: $mainfontcolor;
                text-decoration: underline; } } }

    .contact-form-section {
        margin-bottom: 35px; }

    .form-check {
        display: flex;
        align-items: center;
        .form-check-label {
            margin-top: 0;
            padding: 0 20px; } }

    .form-button-wrapper {
        margin: 25px 0;
        display: flex;
        flex-direction: flex-start; }

    input.invalid {
        background-color: #ffdddd; }

    label {
        font-size: 19px;
        margin-top: 20px;
        color: grey;
        width: 100%; }

    .form-error {
        border: 2px solid rgba(208, 0, 0, 0.8);
        padding: 25px;
        background-color: rgba(208, 0, 0, 0.25); }

    .form-success {
        border: 2px solid rgba(46, 213, 138, 0.8);
        padding: 25px;
        background-color: rgba(46, 213, 138, 0.25); }

    .error {
        border: 2px solid rgba(208, 0, 0, 0.8);
        background-color: rgba(208, 0, 0, 0.25); }

    &.application-form {
        border-top: 1px solid #DCDCDC;
        padding-top: 10rem; }
    &.twoForm {
        padding-top: 14rem;
        max-width: 1680px;
        margin: 0 auto;
        padding: 6.25*$base-width 2.5*$base-width 0 2.5*$base-width;
        @media (min-width: #{map-get($breakpoints, xxl)}) {
            padding: 17.5*$base-width 2.5*$base-width 0 2.5*$base-width; } } }


.seperator-lines-wrap {
    display: block;
    @media (min-width: #{map-get($breakpoints, xxl)}) {
        justify-content: center;
        display: flex;
        gap: 6rem;
        padding-bottom: 10rem; }


    .seperator-line {
        height: 2px;
        width: 100%;
        margin-bottom: 20px;
        @media (min-width: #{map-get($breakpoints, xxl)}) {
            width: 2px;
            height: 100%; } }

    .seperator-line.right {
        background-color: $mainyellow; }

    .seperator-line.left {
        background-color: $mainblue;
        margin-bottom: 60px;
        @media (min-width: #{map-get($breakpoints, xxl)}) {
            margin-bottom: 0; } } }


form {
    .form-group.file-upload {
        flex-direction: unset;
        display: block;

        input.form-control {
            margin-bottom: -64px;
            padding-left: 7rem;
            display: block;
            width: 100%;

            @media (min-width: #{map-get($breakpoints, xxl)}) {
                padding-left: 7rem; }

            &::file-selector-button {
                opacity: 0;
                z-index: 8;
                cursor: pointer; } }

        .hidden-file {
            padding: 2.7*$base-width 2*$base-width;
            z-index: 0;
            transition: all .3s ease-in-out;
            display: inline-block; }

        .hidden-file.blue::hover {
            background-color: $mainyellow;

            @media (min-width: #{map-get($breakpoints, xxl)}) {
                padding: 1.6rem; }

            &:hover, &:focus, &:active, &.active {
                background-color: $mainblue;
                color: #fff;
                text-decoration: none;
                box-shadow: none; }
            outline: none; } }

    .textArea_headline {
        margin: 0; } }


.form-group {
    margin-bottom: 2*$base-width;
    overflow: hidden; }


//contactinfos in form


.block-contact-form {
    .contactblock_wrapper {
        padding: 3.5rem;
        min-width: 90%;
        padding-left: 0;
        padding-top: 0;


        ul:first-of-type li:nth-of-type(2) {
            margin-left: 3.2rem; }

        ul:nth-of-type(2) li:nth-of-type(1) {
            margin-bottom: 1rem; }

        h3 {
            font-size: 2.5rem;
            font-weight: 400;
            margin-bottom: 3.5rem;

            @media only screen and (min-width: #{map-get($breakpoints, md)}) {
                font-size: 4rem; } }

        ul {
            list-style: none;

            &:nth-of-type(1) {
                margin-bottom: 2rem; }

            &:nth-of-type(2) {
                margin-bottom: 2rem; }

            li {
                display: flex;
                flex-direction: row;
                font-weight: 400;

                a {
                    text-decoration: none;

                    &:hover {
                        color: $mainfontcolor; } }

                span {
                    margin-right: 1.2rem;

                    svg {
                        width: 2rem;
                        height: auto;
                        fill: $mainfontcolor; } } } } } }



// honeypot
.form-email-confirm {
    position: absolute;
    left: -20000px; }

#confirm {
    position: absolute;
    left: -20000px; }

