.block-contactblock {
    max-width: 1680px;
    width: 100%;
    margin: 4rem auto;
    padding: 0 2.5*$base-width;


    .contactblock {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4.2rem;

        @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
            flex-direction: row; }

        .contactblock_wrapper {
            -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
            box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
            padding: 2.5rem;
            min-width: 100%;

            @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
                padding: 3.5rem; }
            @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
                min-width: auto;
                padding-right: 14.5rem; }

            @media only screen and (min-width: #{map-get($breakpoints, md)}) {
                padding: 8rem;
                padding-right: 6rem; }

            @media only screen and (min-width: #{map-get($breakpoints, xxxl)}) {
                width: 80rem; }

            @media only screen and (min-width: #{map-get($breakpoints, lg)}) and (max-width: #{map-get($breakpoints, xxxl)}) {
                flex-basis: 50%; }



            ul:first-of-type li:nth-of-type(2) {
                margin-left: 3.2rem; }

            ul:nth-of-type(2) li:nth-of-type(1) {
                margin-bottom: 1rem; }

            h3 {
                font-size: 2.5rem;
                font-weight: 400;
                margin-bottom: 1.5rem;

                @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
                    font-size: 3.5rem;
                    margin-bottom: 3.5rem; }

                @media only screen and (min-width: #{map-get($breakpoints, lg)}) {
                    font-size: 4rem; } }

            ul {
                list-style: none;

                &:nth-of-type(1) {
                    margin-bottom: 2rem; }

                &:nth-of-type(2) {
                    margin-bottom: 2rem; }

                li {
                    display: flex;
                    flex-direction: row;
                    font-weight: 400;

                    a {
                        text-decoration: none;

                        &:hover {
                            color: $mainfontcolor; } }

                    span {
                        margin-right: 1.2rem;

                        svg {
                            width: 2rem;
                            height: auto;
                            fill: $mainfontcolor; } } } }

            .contactblock--formbuttons {
                margin-top: 4rem;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                gap: 2rem;
                flex-wrap: wrap;
                @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
                    margin-top: 6rem; }
                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    width: 100%;

                    a {
                        width: 100%; }

                    .button-tmpl {
                        padding: 2rem 3.4rem;
                        width: 100%; } } } } } }


.email-icon-span {
    display: flex; }
