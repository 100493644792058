$button-bg: #444;
$button-color: #fff;
$button-bg-hover: #fff;
$button-color-hover: #444;
$gallery-sort-overlay: #005d1f;




.block-gallery-sort {
    border-top: 1px solid #DCDCDC;
    padding-bottom: 8rem;
    padding-top: 6rem;
    @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
        padding-top: 12rem;
        padding-bottom: 14rem; }

    .gallery-sort-heading-container {
        padding: 0 1.5rem; }
    .grid {
        max-width: 900px;
        margin: 0 auto; }
    .gallery-sort-images-buttons-row {
        padding: 15px;
        margin: 0;
        .gallery-sort-filter-button {
            background-color: $button-bg;
            color: $button-color;
            transition: $transition;
            padding: 15px 30px;
            margin: 10px 15px 10px 0px;
            border: solid 2px $button-bg;
            border-radius: 15px;
            p {
                margin: 0;
                padding: 0; }
            &:hover {
                background-color: $button-bg-hover;
                color: $button-color-hover;
                transition: $transition;
                border: solid 2px $button-bg;
                cursor: pointer; } }
        .gallery-sort-filter-button-active {
            background-color: $button-bg-hover;
            color: $button-color-hover;
            transition: $transition;
            border: solid 2px $button-bg; } }
    .gallery-sort-images-row {
        padding: 0;
        margin: 0; }
    .gallery-sort-image-container {
        margin: 0 auto;
        margin-bottom: 0;
        width: 100%;
        max-width: 100%;
        height: 350px;
        @media (min-width: #{map-get($breakpoints, sm)}) {
            height: 400px; }
        @media (min-width: #{map-get($breakpoints, md)}) {
            height: 600px;
            max-width: 900px !important; }
        img,picture {
            max-width: 900px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative;
            .gallery-sort-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $mainblue;
                opacity: 0;
                transition: $transition;
                z-index: 9; }
            &:hover {
                .gallery-sort-overlay {
                    max-width: 900px;
                    opacity: 0.6;
                    transition: $transition; } } } }
    h1,h2,h3,h4 {
        margin-bottom: 8rem; } }



.fancybox-caption {
    font-size: 25px;
    @media (max-width: #{map-get($breakpoints, l)}) {
        font-size: 20px; }
    @media (max-width: #{map-get($breakpoints, m)}) {
        font-size: 16px; } }
