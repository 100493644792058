@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/jost-v13-latin-300.woff2') format("woff2"),
  url('../../fonts/jost-v13-latin-300.woff') format("woff") {} }


@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/jost-v13-latin-regular.woff2') format("woff2"),
  url('../../fonts/jost-v13-latin-regular.woff') format("woff") {} }


@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/jost-v13-latin-500.woff2') format("woff2"),
  url('../../fonts/jost-v13-latin-500.woff') format("woff"), }



@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/jost-v13-latin-700.woff2') format("woff2"),
  url('../../fonts/jost-v13-latin-700.woff') format("woff") {} }



@font-face {
  font-family: 'Jost';
  font-style: italic;
  font-weight: 300;
  src: url('../../fonts/jost-v13-latin-300italic.woff2') format("woff2"),
  url('../../fonts/jost-v13-latin-300italic.woff') format("woff") {} }



@font-face {
  font-family: 'Jost';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/jost-v13-latin-italic.woff2') format("woff2"),
  url('../../fonts/jost-v13-latin-italic.woff') format("woff") {} }



@font-face {
  font-family: 'Jost';
  font-style: italic;
  font-weight: 500;
  src: url('../../fonts/jost-v13-latin-500italic.woff2') format("woff2"),
  url('../../fonts/jost-v13-latin-500italic.woff') format("woff") {} }



@font-face {
  font-family: 'Jost';
  font-style: italic;
  font-weight: 700;
  src: url('../../fonts/jost-v13-latin-700italic.woff2') format("woff2"),
  url('../../fonts/jost-v13-latin-700italic.woff') format("woff") {} }


@font-face {
  font-family: 'Antic Slab';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/antic-slab-v16-latin-regular.woff2') format("woff2"),
  url('../../fonts/antic-slab-v16-latin-regular.woff') format("woff") {} }
