.block-subpageintro {
  padding: 0;

  .subpage_intro {
    display: flex;

    h1 {
      color: $mainfontcolor;
      padding-bottom: 2rem; }

    .subpage_intro_wrapper_left {
      flex-basis: 50%;
      padding: 6*$base-width 2.5*$base-width 6*$base-width 2.5*$base-width;
      @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
        padding: 10*$base-width 2.5*$base-width 10*$base-width 2.5*$base-width; }

      @media only screen and (min-width: #{map-get($breakpoints, lg)}) {
        padding: 10*$base-width 2.5*$base-width 2.5*$base-width 2.5*$base-width; }

      @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
        padding: 10rem 8rem; }

      @media only screen and (min-width: #{map-get($breakpoints, xxxl)}) {
        padding: 14rem 10rem 12rem 14rem; }

      @media only screen and (min-width: #{map-get($breakpoints, xxxxl)}) {
        flex-basis: 50%;
        padding: 22rem 26rem 16.5rem 14rem;

        .color-filter {
          height: 100% !important; }

        .right_image {
          height: 100% !important; } } }

    .subpage_intro_wrapper_right {
      flex-basis: 100%;

      @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
        flex-basis: 50%;

        .color-filter {
          height: 100% !important; }

        .right_image {
          height: 100% !important; } } } }

  .subpage_intro_icon-loop_wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    @media only screen and (min-width: #{map-get($breakpoints, md)}) {
      flex-direction: row;
      gap: 10rem; }

    @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
      gap: 7rem; }

    .subpage_intro_icon-loop {
      display: flex;
      flex-wrap: wrap;

      &:nth-child(2n) {
        flex-basis: 100%; } }

    .leistungs-teaser {
      margin-bottom: 1.5rem;

      .leistungs-teaser-inner {
        display: flex;
        align-items: center;
        gap: 2rem;

        .icon {
          width: 5rem;
          height: 5rem;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 3.6rem;
            height: 3.6rem; } }

        span {
          font-weight: 500;
          font-size: 2rem; } }

      .subpage_intro_wrapper_right {
        flex-basis: 100%;

        @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
          flex-basis: 50%; } } } } }


//specific styles for topic

//elektrotechnik
.subpage_intro.elektrotechnik {
  .icon {
    background-color: $mainyellow; }

  .subpage_intro_wrapper_right {
    .color-filter {
      width: 100%;
      height: 25rem;
      position: absolute;
      background-color: $mainyellow;
      opacity: 0.4;
      z-index: 1;
      @media only screen and (min-width: #{map-get($breakpoints, md)}) {
        height: 30rem; }
      @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
        height: 40rem; }
      @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
        width: 50%; } }

    .right_image {
      height: 25rem;
      background-position: center;
      background-size: cover;
      filter: grayscale(1);
      @media only screen and (min-width: #{map-get($breakpoints, md)}) {
        height: 30rem; }
      @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
        height: 40rem; } } } }


.subpage_intro.hausgeraete {
  .icon {
    background-color: $mainblue; }

  .subpage_intro_wrapper_right {
    .color-filter {
      width: 100%;
      height: 25rem;
      position: absolute;
      background-color: $mainblue;
      opacity: 0.4;
      z-index: 1;
      @media only screen and (min-width: #{map-get($breakpoints, md)}) {
        height: 30rem; }
      @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
        height: 40rem; }

      @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
        width: 50%; } }

    .right_image {
      height: 25rem;
      background-position: center;
      background-size: cover;
      filter: grayscale(1);
      @media only screen and (min-width: #{map-get($breakpoints, md)}) {
        height: 30rem; }
      @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
        height: 40rem; } } } }

.subpage_intro.imgposition-left {
  flex-direction: column-reverse;

  @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
    flex-direction: row-reverse; } }

.subpage_intro.imgposition-right {
  flex-direction: column-reverse;

  @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
    flex-direction: row; } }
