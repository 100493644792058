* {
    padding: 0;
    margin: 0;
    box-sizing: border-box; }

html {
    font-size: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth; }

body {
    -webkit-text-size-adjust: none;
    font-size: 1.6rem;
    color: $body-color;
    line-height: 160%;
    font-weight: 400;
    font-family: $font-default, sans-serif;
    overflow-x: hidden;

    @media only screen and (min-width: #{map-get($breakpoints, s)}) {
        font-size: 2rem; }

    &.customize-support {
 } }        // if logged in
img {
    display: block;
    max-width: 100%; }

main {
    position: relative;
    margin-top: 70px;
    @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
        margin-top: 8vh; } }


.block {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0 2 * $base-width;

    @media only screen and (min-width: #{map-get($breakpoints, s)}) {
        padding: 0 4 * $base-width; }

    .block:not(.block-container) {
        padding: 0; } }

.container {
    max-width: 1680px;
    margin: 0 auto;
    padding: 0 2.5*$base-width; }

// @media only screen and (min-width: #{map-get($breakpoints, md)})
//     .container
//         padding-left: 6.5rem
//         padding-right: 6.5rem

// @media only screen and (min-width: #{map-get($breakpoints, lg)})
//     .container
//         padding-left: 8.5rem
//         padding-right: 8.5rem

// @media only screen and (min-width: #{map-get($breakpoints, xl)})
//     .container
//         padding-left: 15.5rem
//         padding-right: 15.5rem

// @media only screen and (min-width: #{map-get($breakpoints, xxl)})
//     .container
//         padding-left: 35.5rem
//         padding-right: 35.5rem

.seperator {
    height: 1px;
    width: 100%;
    background-color: $maingrey; }

#errorPage {
    margin-top: 15vh;
    margin-bottom: 65vh; }
