footer {
    padding: 3.1rem 0;
    background: $secondfontcolor;

    ul {
        display: flex;
        list-style: none;
        justify-content: center;
        gap: 4rem;

        li {
            display: flex;
            justify-content: center;
            // @media only screen and (max-width: #{map-get($breakpoints, md)})
            //     flex-basis: 33%
            a {
                text-decoration: none;
                font-size: 1.8rem;

                &:hover {
                    text-decoration: none;
                    color: $mainblue; } } } } }
