.block.block-teaserslider {
    padding: 5rem;
    margin: 0 auto;
    background: $maingrey;

    .teaserslider {
        .teaser-item {
            padding: 2 * $base-width 0;

            .img-wrapper {
                position: relative;
                padding-top: 100%;
                display: block;
                height: 0;

                img {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center; } }

            .content-wrapper {
                background-color: white;
                padding: 3 * $base-width;
                transform: translateY(-2 * $base-width);
                text-align: center;
                z-index: 99;
                position: relative;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
                transition: $transition;
                display: flex;
                justify-content: space-between;
                align-content: center;
                flex-wrap: wrap;

                .insta-info-wrap {
                    display: flex;
                    gap: 1rem;

                    .insta-logo-wrap {
                        width: 50px;
                        height: 50px;
                        object-fit: cover;
                        border-radius: 50%;
                        border: 1px solid $mainblue;
                        img {
                            width: 100%;
                            height: auto; } } }

                .insta-username {
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    font-size: 1.5rem; }

                .content-icon_wrap {
                    width: 30px;
                    height: auto;
                    svg {
                        width: 100%;
                        height: 100%; } }

                &:hover {
                    box-shadow: 0 0 15px rgba(0, 0 ,0, 0.2);
                    transform: translateY(-3 * $base-width);
                    transition: $transition; }

                .h3, h3 {
                    flex: 0 0 100%; }

                @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                    margin: -2 * $base-width 2 * $base-width 0 2 * $base-width; } } }

        .owl-dots {
            display: flex;
            justify-content: center;
            padding: 2 * $base-width 0;

            button {
                span {
                    height: 15px;
                    width: 15px;
                    display: block;
                    border: 1px solid $mainblue;
                    border-radius: 25px;
                    margin: $base-width; }

                &.active {
                    span {
                        background-color: $mainblue; } } } }
        .owl-nav {
            position: absolute;
            top: 40%;
            left: -6 * $base-width;
            right: -6 * $base-width;
            display: flex;
            justify-content: space-between;

            @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                left: -2 * $base-width;
                right: -2 * $base-width; }

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                display: none; }

            button {
                @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                    height: 45px;
                    width: 45px;
                    background-color: white;
                    border-radius: 25px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); }

                i {
                    color: $mainblue;
                    font-size: 2.4rem;

                    @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                        display: flex;
                        justify-content: center; } }

                &.disabled {
                    cursor: no-drop;

                    i {
                        opacity: 0.4; } } } } } }
