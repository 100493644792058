.block-accordion {
  margin: 0 auto;
  padding-bottom: 8rem;
  @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
    padding-bottom: 12rem; }

  .jumpmark {
    top: -100px;
    position: relative; }

  h2 {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 4rem;

    @media only screen and (min-width: #{map-get($breakpoints, md)}) {
      margin-top: 6rem;
      margin-bottom: 6rem; }

    @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
      margin-bottom: 8rem;
      margin-top: 14rem; } }

  .accordion {
    max-width: 90rem;
    width: 100%;
    margin: 0 auto;
    .accordion-heading {
      padding: 2.5rem;
      margin-bottom: 2rem;
      background-color: $maingrey;
      transition: $transition;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      -webkit-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.34);
      -moz-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.34);
      box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.34);
      @media only screen and (min-width: #{map-get($breakpoints, md)}) {
        padding: 2.5rem 3rem 2.5rem 4rem; }

      h3, a {
        color: $mainfontcolor;
        transition: $transition; }

      h3 {
        text-transform: uppercase;
        font-size: 1.8rem;
        display: flex;
        align-items: center;
        line-height: 30px;
        letter-spacing: 1.25px;
        margin-bottom: 0;
        font-weight: 500;
        @media only screen and (min-width: #{map-get($breakpoints, md)}) {
          font-size: 2.5rem; } }

      .accordion-icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;


        .accordion-icon {
          width: 33px;
          height: 33px;
          transition: $transition;
          filter: $filter-color-1;
          border: 1px solid black;
          border-radius: 50%;
          padding: 7px; } }

      &:hover, &-active {
        background-color: $darkgrey;

        h3, a {
          color: $secondfontcolor; }

        .accordion-icon-wrapper {
          .accordion-icon {
            filter: $filter-color-3; } } }

      &-active {

        .accordion-icon-wrapper {
          .accordion-icon {
            transform: rotate(180deg); } } } } }
  .accordion-anchor {
    position: relative;
    .accordion-jumpmark {
      position: absolute;
      top: -85px; } }

  .accordion-body {
    padding: 2rem 2.5rem 4rem 2.5rem;
    margin: auto;
    @media only screen and (min-width: #{map-get($breakpoints, md)}) {
      padding: 2rem 3rem 6rem 4rem; }
    p {
      font-size: 1.8rem;
      line-height: 30px;
      letter-spacing: 0.8px;
      @media only screen and (min-width: #{map-get($breakpoints, md)}) {
        font-size: 2rem;
        line-height: 35px;
        letter-spacing: 1px; } }
    h4 {
      line-height: 30px;
      padding-top: 2*$base-width;
      color: #232227;
      margin-bottom: 2*$base-width;
      font-size: 2.2rem;
      @media only screen and (min-width: #{map-get($breakpoints, md)}) {
        font-size: 2.5rem;
        padding-top: 2.5*$base-width;
        margin-bottom: 2.5*$base-width; } }
    ul {
      li {
        font-family: $font-default;
        font-size: 1.8rem;
        padding-bottom: 12px;
        list-style-type: none;
        @media only screen and (min-width: #{map-get($breakpoints, md)}) {
          font-size: 2rem;
          padding-bottom: 15px; }
        &:before {
          content: "-";
          padding-right: 15px; } } } }

  &.longTab {
    max-width: 1250px;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    margin: 0 auto;
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
      max-width: 100%; }
    .accordion {
      max-width: 100%;
      .accordion-heading {
        h3 {
          font-size: 2.2rem;
          text-transform: inherit;
          letter-spacing: 1px; } }
      .accordion-body {
        width: 100%; } } }
  &.job {
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
      padding-top: 5rem;
      border-top: 1px solid #DCDCDC;
      margin-top: 6rem; }
    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
      padding-top: 3rem;
      margin-top: 4rem; }
    @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
      margin-top: 0; } } }

.accordion.faq {
  margin-top: 10rem; }
