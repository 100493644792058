.block.block-textcontent {
    max-width: 1250px;
    width: 100%;
    padding: 10*$base-width 2.5*$base-width 2.5*$base-width 2.5*$base-width;
    margin: 0 auto;
    @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
        padding: 16.25*$base-width 2.5*$base-width 9.5*$base-width 2.5*$base-width; }

    .block-inner {
        max-width: $boxed;
        margin: 0 auto;

        &.align-center {
            max-width: $small;
            text-align: center;

            ul {
                margin: auto; } }

        h1, .h1 {
            margin-bottom: 20px;
            @media only screen and (min-width: #{map-get($breakpoints, lg)}) {
                margin-bottom: 40px;
                line-height: 57px; } }

        h2, .h2 {
            font-size: 3.2rem;

            @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
                font-size: 4.2rem; }

            @media only screen and (min-width: #{map-get($breakpoints, lg)}) {
                font-size: 6rem;
                line-height: 57px;
                margin-bottom: 10px; } }

        .text-wrapper {
            // display: flex
            // justify-content: space-between
            flex-wrap: wrap;
            color: $mainfontcolor;

            .text {
                flex: 0 0 46%;
                width: 46%;
                color: $mainfontcolor;
                text-align: left;

                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    flex: 0 0 48%;
                    width: 48%; }

                @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                    flex: 0 0 47%;
                    width: 47%; }

                @media only screen and (max-width: #{map-get($breakpoints, md)}) {
                    flex: 0 0 100%;
                    width: 100%; } } }

        p, li {
            color: $mainfontcolor; }

        .btn {
            margin-top: 4*$base-width;

            @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                margin-top: 3*$base-width; }

            @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                margin-top: 2*$base-width; } }
        ul {
            li {
                font-family: $font-default;
                font-size: 1.8rem;
                padding-bottom: 12px;
                list-style-type: none;
                @media only screen and (min-width: #{map-get($breakpoints, md)}) {
                    font-size: 2rem;
                    padding-bottom: 15px; }
                &:before {
                    content: "-";
                    padding-right: 15px; } } } } }


// Styling for frontpage

.block-textcontent.text-landingpage {
    h3 {
        font-weight: 400;
        margin-bottom: 4rem;
        line-height: 30px; } }
