.block.block-text-media {
    padding: 0;

    @media only screen and (min-width: #{map-get($breakpoints, lg)}) {
        margin-top: 6rem; }

    @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
        margin-top: 14rem; }

    .text-media-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media only screen and (min-width: #{map-get($breakpoints, xxxl)}) {
            flex-wrap: wrap-reverse; }

        &.img-right {
            flex-direction: row;
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                flex-direction: column-reverse; } }

        &.img-left {
            flex-direction: row-reverse;
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                flex-direction: column-reverse; } }

        .text-wrapper {
            flex: 0 0 67.5%;
            max-width: 100%;
            padding: 5*$base-width 2.5*$base-width 0 2.5*$base-width;
            @media only screen and (min-width: #{map-get($breakpoints, md)}) {
                padding: 7.5*$base-width 2.5*$base-width 0 2.5*$base-width; }
            @media only screen and (min-width: #{map-get($breakpoints, xxxl)}) {
                margin-right: auto;
                max-width: 990px;
                margin-left: 17.5*$base-width; }
            p {
                line-height: 35px; } }

        .media-wrapper {
            flex: 0 0 32.5%;
            position: relative;
            padding-top: 60%;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

            @media only screen and (min-width: #{map-get($breakpoints, md)}) {
                padding-top: 29.5%; }

            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                width: 100%; }

            img, iframe {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: grayscale(1); }

            .color-filter {
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 1;
                top: 0;
                opacity: 0.4; }

            .color-filter.yellow {
                background-color: $mainyellow; }

            .color-filter.blue {
                background-color: $mainblue; } } } }
