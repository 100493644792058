/* Third-Party */
// @import '~bootstrap/scss/bootstrap'
@import '~owl.carousel/dist/assets/owl.carousel';
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min';


/* Globals */
@import './globals/vars';
@import './globals/fonts';
@import './globals/base';
@import './globals/mixins';
@import './globals/bem-grid-beta';

/* Layout-Parts */
@import './layout/typo';
@import './layout/header';
@import './layout/footer';
@import './layout/buttons';

/* Blocks */
@import '../../blocks/introduction/style';
@import '../../blocks/subpageintro/style';
@import '../../blocks/textcontent/style';
@import '../../blocks/contactblock/style';
@import '../../blocks/contactblocksubpage/style';
@import '../../blocks/openingblock/style';
@import '../../blocks/accordion/style';
@import '../../blocks/text-media/style';
@import '../../blocks/contact-form/style';
@import '../../blocks/teaserslider/style';
@import '../../blocks/employee-sort/style';
@import '../../blocks/gallery-sort/style';
