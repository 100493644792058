$employee-sort-button-bg: #444;
$employee-sort-button-color: #fff;
$employee-sort-button-bg-hover: #fff;
$employee-sort-button-color-hover: #444;

.block-employee-sort {
    margin: 0 auto;
    border-top: 1px solid #DCDCDC;
    margin-top: 4rem;
    padding-bottom: 8rem;
    padding-top: 5rem;

    @media only screen and (min-width: #{map-get($breakpoints, xxxl)}) {
        margin-top: 14rem;
        padding-bottom: 14rem;
        padding-top: 14rem; }

    .employee-sort-container {
        max-width: 1920px;
        margin: 0 auto; }
    .employee-buttons-row {
        padding: 15px;
        margin: 0;
        .employee-filter-button {
            background-color: $employee-sort-button-bg;
            color: $employee-sort-button-color;
            transition: $transition;
            padding: 15px 30px;
            margin: 10px 15px 10px 0px;
            border: solid 2px $employee-sort-button-bg;
            border-radius: 15px;
            p {
                margin: 0;
                padding: 0; }
            &:hover {
                background-color: $employee-sort-button-bg-hover;
                color: $employee-sort-button-color-hover;
                transition: $transition;
                border: solid 2px $employee-sort-button-bg;
                cursor: pointer; } }
        .employee-filter-button-active {
            background-color: $employee-sort-button-bg-hover;
            color: $employee-sort-button-color-hover;
            transition: $transition;
            border: solid 2px $employee-sort-button-bg; } }
    .employee-container {
        margin: 0;
        margin-bottom: 0 !important;
        width: 100%;
        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
            padding-top: 5*$base-width !important; }
        .employee-picture-container {
            img,picture {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: relative; } }
        .employee-info-container {
            background-color: $maingrey;
            color: $mainfontcolor;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media only screen and (min-width: #{map-get($breakpoints, md)}) {
                padding: 4rem; }
            h4 {
                margin-bottom: 25px;
                font-size: 1.8rem;
                font-weight: 500;
                font-family: "Jost";
                color: $mainfontcolor;
                @media only screen and (min-width: #{map-get($breakpoints, md)}) {
                    font-size: 2.2rem; } }

            h3 {
                color: $mainfontcolor;
                line-height: 40px;
                font-weight: 500; } }

        .employee-info--item-container {
            display: flex;
            align-items: center;

            svg {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                fill: $mainfontcolor; }
            p, a {
                margin: 0;
                padding: 0;
                font-size: 1.8rem;
                color: $mainfontcolor;
                @media only screen and (min-width: #{map-get($breakpoints, md)}) {
                    font-size: 1.9rem; } }
            a {
                text-decoration: none;
                &:hover {
                    text-decoration: underline; } } } } }

