
$button-primary-bg: #081B26;
$button-primary-bg-hover: #fff;
$button-primary-color: #fff;
$button-primary-color-hover: #081B26;

$button-secondary-bg: #fff;
$button-secondary-bg-hover: #081B26;
$button-secondary-color: #081B26;
$button-secondary-color-hover: #fff;


.button {
  border-radius: 25px;
  height: 50px;
  padding: 10px 40px;
  font-size: 1.9rem;
  text-decoration: none;
  border: none;
  transition: $transition; }

.button-primary {
  background-color: $button-primary-bg;
  border: 2px solid $button-primary-bg;
  color: $button-primary-color;
  transition: $transition;
  &:hover {
    background-color: $button-primary-bg-hover;
    border: 2px solid $button-primary-bg;
    color: $button-primary-color-hover;
    transition: $transition; } }


.button-secondary {
  background-color: $button-secondary-bg;
  border: 2px solid $button-secondary-color;
  color: $button-secondary-color;
  transition: $transition;
  &:hover {
    background-color: $button-secondary-bg-hover;
    border: 2px solid $button-secondary-color;
    color: $button-secondary-color-hover;
    transition: $transition; } }


//custom buttons

.button_tmpl {
    border: 0;
    padding: 2rem 2.5rem;
    font-size: 1.8rem;
    font-family: "Jost";
    font-weight: 400;
    text-transform: uppercase;
    transition: all .3s;
    cursor: pointer;
    @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
      font-size: 2.2rem;
      padding: 2rem 4.4rem; } }

.button_tmpl.yellow {
    background-color: $mainyellow;
    color: $mainfontcolor;

    &:hover {
        color: $secondfontcolor;
        background-color: $mainblue; } }

.button_tmpl.blue {
    background-color: $mainblue;
    color: $secondfontcolor;

    &:hover {
        color: $mainfontcolor;
        background-color: $mainyellow; } }

.button_tmpl.hidden-file {
    font-size: 1.8rem; }
