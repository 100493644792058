.block-contactblocksubpage {
    border-top: 1px solid #DCDCDC;
    margin-bottom: 8rem;
    padding-top: 10*$base-width;
    @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
        margin-bottom: 14rem;
        padding-top: 15*$base-width; }

    .headline_wrapper {
        margin-bottom: 4rem;

        @media only screen and (min-width: #{map-get($breakpoints, lg)}) {
            margin-bottom: 8rem; }

        h2 {
            text-align: center;
            text-transform: uppercase; } }

    .contactblocksubpage_wrapper {
        margin-bottom: 4rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 4rem;

        @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
            flex-direction: row; }

        .contactblock_wrapper {
            -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
            box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
            padding: 2.5rem;
            max-width: 100%;
            height: 100%;

            @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
                padding: 3.5rem; }

            @media only screen and (min-width: #{map-get($breakpoints, md)}) {
                padding: 8rem;
                padding-right: 14.5rem; }

            @media only screen and (min-width: #{map-get($breakpoints, xxxl)}) {
                width: 80rem; }

            @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
                flex-basis: 50%; }


            ul:first-of-type li:nth-of-type(2) {
                margin-left: 3.2rem; }

            ul:nth-of-type(2) li:nth-of-type(1) {
                margin-bottom: 1rem; }

            h3 {
                font-size: 2.5rem;
                font-weight: 400;
                margin-bottom: 1.5rem;

                @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
                    font-size: 3.5rem;
                    margin-bottom: 3.5rem; }

                @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
                    font-size: 4rem; } }

            ul {
                list-style: none;

                &:nth-of-type(1) {
                    margin-bottom: 2rem; }

                &:nth-of-type(2) {
                    margin-bottom: 2rem; }

                li {
                    display: flex;
                    flex-direction: row;
                    font-weight: 400;
                    font-size: 1.8rem;
                    @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
                        font-size: 2rem; }

                    a {
                        text-decoration: none;

                        &:hover {
                            color: $mainfontcolor; } }

                    span {
                        margin-right: 1.2rem;

                        svg {
                            width: 2rem;
                            height: auto;
                            fill: $mainfontcolor; } } } }

            .contactblock--formbuttons {
                margin-top: 4rem;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                gap: 2rem;
                flex-wrap: wrap;
                @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
                    margin-top: 6rem; } } } }


    .openingblock_wrapper {
        -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
        padding: 2.5rem;
        max-width: 100%;
        height: 100%;

        @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
            padding: 3.5rem; }

        @media only screen and (min-width: #{map-get($breakpoints, md)}) {
            padding: 8rem; }

        @media only screen and (min-width: #{map-get($breakpoints, xxxl)}) {
            width: 80rem; }

        @media only screen and (min-width: #{map-get($breakpoints, lg)}) {
            flex-basis: 50%; }

        h3 {
            line-height: 40px;
            display: flex;
            margin-bottom: 3.75*$base-width;
            align-items: center;
            text-transform: uppercase;
            font-size: 2.5rem;
            font-weight: 400;
            margin-bottom: 1.5rem;

            @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
                font-size: 3.5rem;
                margin-bottom: 3.5rem; }

            @media only screen and (min-width: #{map-get($breakpoints, lg)}) {
                font-size: 4rem; }

            span {
                padding-right: 15px;
                svg {
                    width: 3.4rem;
                    height: auto;
                    fill: $mainfontcolor; } } }

        .table-wrapper {
            table {
                tr {
                    display: flex;
                    justify-content: space-between;

                    td {
                        font-size: 1.8rem;
                        @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
                            font-size: 2rem; } } }

                tr {
                    td:nth-of-type(1) {
                        font-weight: 500;
                        padding-right: 1rem; } }

                tr:nth-of-type(1) {
                    padding-bottom: 1rem; }

                tr:nth-of-type(2) {
                    padding-bottom: 2rem; }

                tr:nth-of-type(3) {
                    padding-bottom: 1.5rem; } }

            p {
                font-style: italic; }

            div.dscr {
                display: flex;
                flex-direction: column;
                line-height: 28px;
                font-size: 1.8rem;
                padding-top: 1rem;
                @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
                    font-size: 2rem;
                    padding-top: 2rem;
                    line-height: 33px; }

                strong {
                    font-weight: 500; } } } } }
