body {
    header {
        position: fixed;
        z-index: 999;
        width: 100%;
        background: $header-bg;
        -webkit-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.34);
        -moz-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.34);
        box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.34);

        nav {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .navbar-toggler {
                height: 7rem;
                width: 8.2rem;
                margin-left: 0;
                padding: 2.4rem;
                border-color: transparent;
                outline: 0;
                z-index: 5;
                text-align: right;
                background-color: $mainblue;
                border-radius: 0; }

            .navbar-toggler.collapsed {

                .top-bar {
                    -webkit-animation: top-bar .4s linear forwards;
                    -o-animation: top-bar .4s linear forwards;
                    animation: top-bar .4s linear forwards;
                    margin-bottom: -2px;
                    background-color: $secondfontcolor;
                    width: 40px; }

                .middle-bar {
                    -webkit-animation: middle-bar .4s linear forwards;
                    -o-animation: middle-bar .4s linear forwards;
                    animation: middle-bar .4s linear forwards;
                    display: none; }

                .bottom-bar {
                    -webkit-animation: bottom-bar .4s linear forwards;
                    -o-animation: bottom-bar .4s linear forwards;
                    animation: bottom-bar .4s linear forwards;
                    background-color: $secondfontcolor;
                    width: 40px;
                    margin-left: 0; } }

            .sr-only {
                position: absolute;
                width: 1px;
                height: 1px;
                padding: 0;
                margin: -1px;
                overflow: hidden;
                clip: rect(0, 0, 0, 0);
                white-space: nowrap;
                border: 0; }

            .icon-bar {
                display: block;
                height: 2px;
                margin-bottom: 8px;
                background-color: #fff;
                transition: all 0.2s; }

            .top-bar {
                width: 40px; }

            .middle-bar {

                width: 40px; }

            .bottom-bar {
                width: 30px;
                margin-bottom: 0px;
                margin-left: auto; }

            @media (max-width: #{map-get($breakpoints, xl)}) {
                a.logo.desktop {
                    display: none; } }

            @media (min-width: #{map-get($breakpoints, xl)}) {
                a.logo.mobile {
                    display: none; } }

            @media (min-width: #{map-get($breakpoints, xxl)}) {
                .navbar-toggler {
                    display: none; } }

            @media (min-width: #{map-get($breakpoints, xxxl)}) {
                a.logo {
                    margin-left: 13.9rem !important; } }

            a.logo {
                margin-left: 2rem; }

            @media (min-width: #{map-get($breakpoints, xl)}) {
                a.logo {
                    margin-left: 6rem; } }

            a.logo.mobile img {
                height: 7rem;
                width: auto; }

            a.logo.desktop img {
                height: 5rem;
                width: auto; }

            @media (min-width: #{map-get($breakpoints, xxxl)}) {
                a.logo {
                    img {
                        height: 5.8rem;
                        width: auto; } } } }


        @media (max-width: #{map-get($breakpoints, xxl)}) {
            ul.navbar-nav {
                display: none;
                width: 100%;
                max-width: 100%;
                height: auto;
                padding: 1.6rem 0.8rem;
                margin: 0 !important;
                justify-content: center;
                position: fixed;
                top: 7rem;
                right: 0;
                left: 0;
                background: $secondfontcolor;
                text-align: center;
                z-index: 1000;
                transition: all .4s ease-in; }

            .navbar-collapse.show {
                ul.navbar-nav {
                    display: flex; } } }

        @media (max-width: #{map-get($breakpoints, xl)}) {
            ul.navbar-nav {
                display: none;
                height: 100%;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-grow: 1;
                right: -100%;
                bottom: 0;
                transition: right .3s;
                padding: 2.4rem;
                overflow: auto; }

            .navbar-collapse.show {
                ul.navbar-nav {
                    display: flex; } } }

        @media (min-width: #{map-get($breakpoints, xxl)}) {
            ul.navbar-nav {
                display: flex; } }

        @media (min-width: #{map-get($breakpoints, xxl)}) {
            ul.navbar-nav {
                gap: 4.8rem !important; } }

        @media (min-width: #{map-get($breakpoints, xxxl)}) {
            ul.navbar-nav {
                margin-right: 15.8rem !important;
                li {
                    a {
                        font-size: 2rem !important; } } } }

        ul.navbar-nav {
            list-style: none;
            gap: 5rem;
            margin-right: 6rem;

            li {
                display: flex;
                align-items: center;

                a {
                    text-decoration: none;
                    text-transform: uppercase;
                    font-size: 2rem;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    transition: all .2s;
                    border-bottom: 3px solid transparent;
                    border-top: 3px solid transparent;

                    &:hover {
                        color: $mainblue;
                        @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
                            border-bottom: 3px solid $mainblue; } } }
                &.active {
                    a {
                        color: $mainblue;
                        @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
                            border-bottom: 3px solid $mainblue; } } } }

            li:last-of-type {
                background-color: $mainblue;
                margin: 2.3rem 0;
                transition: all 0.3s;
                cursor: pointer;

                a {
                    color: $secondfontcolor;
                    padding: 2.32rem 4rem; }

                &:hover {
                    background-color: $mainyellow;

                    a {
                        color: $mainfontcolor;
                        border-bottom: 3px solid transparent; } } } } } }




// keyfranes
@keyframes top-bar {
    0% {
        top: 2px;
        width: 30px;
        transform: rotate(0deg); }
    40% {
        top: 10px;
        width: 30px;
        transform: rotate(0deg); }
    60% {
        width: 30px;
        top: 10px;
        transform: rotate(0deg); }
    100% {
        width: 30px;
        top: 10px;
        transform: rotate(-45deg); } }

@keyframes middle-bar {
    0% {
        top: 18px;
        width: 40px; }
    40% {
        top: 10px;
        width: 35px; }
    60% {
        width: 0px;
        top: 10px; }
    100% {
        width: 0px;
        top: 10px; } }

@keyframes bottom-bar {
    0% {
        top: 18px;
        width: 35px;
        transform: rotate(0deg); }
    40% {
        top: 10px;
        width: 35px;
        transform: rotate(0deg); }
    60% {
        width: 35px;
        top: 10px;
        transform: rotate(0deg); }
    100% {
        width: 30px;
        top: 10px;
        transform: rotate(45deg); } }

html.disable-scroll {
    overflow-x: hidden; }
