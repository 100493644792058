a {
    color: $link-color;
    text-decoration: none;
    transition: $transition;
    font-size: 1.8rem;

    @media only screen and (min-width: #{map-get($breakpoints, lg)}) {
        font-size: 2rem; }


    &:hover {
        color: $mainblue; }

    &:focus {
        outline: 0; }

    &.button {
        display: inline-block;
        text-decoration: none;
        border-radius: 10px;
        padding: 1.5 * $base-width 3 * $base-width;
        color: $button-color;
        transition: $transition;
        background: $button-bg;

        &:hover {
            background: $button-bg-hover; } } }

strong,
.bold {
    font-family: $font-default;
    font-weight: 600; }

p {
    line-height: 1.6;
    font-size: 1.8rem;
    padding-bottom: 3 * $base-width;

    @media only screen and (min-width: #{map-get($breakpoints, lg)}) {
        font-size: 2rem;
        line-height: 33px; } }

li {
    font-size: 1.8rem;

    @media only screen and (min-width: #{map-get($breakpoints, lg)}) {
        font-size: 2rem; } }

button {
    &:focus {
        outline: none; } }

h1,
.h1 {
    font-family: $font-headline, sans-serif;
    font-size: 4rem;
    line-height: 135%;
    display: block;
    font-weight: 400;
    color: $mainblue;

    @media only screen and (min-width: #{map-get($breakpoints, md)}) {
        font-size: 6rem; }

    @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
        font-size: 8.5rem; } }

h2,
.h2 {
    font-family: $font-headline, sans-serif;
    line-height: 30px;
    display: block;
    font-size: 3rem;
    font-weight: 400;
    color: $mainfontcolor;
    margin-bottom: 2 * $base-width;

    @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
        font-size: 4rem;
        line-height: 40px; }

    @media only screen and (min-width: #{map-get($breakpoints, md)}) {
        font-size: 6rem;
        line-height: 60px; } }

h3,
.h3 {
    font-family: $font-headline, sans-serif;
    color: $mainfontcolor;
    font-size: 2.8rem;
    display: block;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 50px;

    @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
        font-size: 4rem;
        line-height: 57px; } }

h4,
.h4 {
    font-family: $font-headline, sans-serif;
    color: $mainfontcolor;
    font-size: 1.6rem;
    line-height: 125%;
    display: block;
    font-weight: 500;
    margin-bottom: 10px;

    @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
        font-size: 1.8rem; }

    @media only screen and (min-width: #{map-get($breakpoints, lg)}) {
        font-size: 2.8rem; } }

.align-center {
    text-align: center; }
